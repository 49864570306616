<template>
    <div class="site__centered">
        <div class="my-applications">
          <div class="my-applications__header">
            <h2 class="my-applications__title mobile-hidden">Past Applications</h2>
          </div>
            <div class="my-applications__row">
                <SideNav/>

                <div class="my-applications__row__col-9 my-applications__row__col-sm-12">
                    <div class="my-applications__tab-column">

                      <ApplicationItem
                        v-for="application in applicationsList"
                        :key="application.id"
                        :application="application"
                      />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ApplicationItem from './ApplicationItem'
import SideNav from '@/components/site/SideNav.vue'

import { prepareApplicationSubmissionMessage } from '@core/mixins/prepareApplicationSubmissionMessage'
import store from '@/store'

export default {
  components: {
    ApplicationItem,
    SideNav,
  },
  mixins: [prepareApplicationSubmissionMessage],
  data: () => ({
    isLoading: false,
    showThankYou: false,
    applicationProgramName: '',
  }),
  async created() {
    store.commit('SET_LOADING_STATUS', true)

    await store.dispatch('applications/fetchApplicationsList',  {without_archived: false})
      .finally(() => {
        store.commit('SET_LOADING_STATUS', false)
      })
  },
  computed: {
    applicationsList () {
      return store.getters['applications/getApplicationsList']
    },
  },
  methods: {},
}
</script>

<style scoped>
@media screen and (max-width: 767px) {
  .message__success {
    font-size: 16px;
    line-height: 20px;
    padding: 20px;
    white-space: pre-wrap;
  }
}
</style>
